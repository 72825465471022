import i18n, { InitOptions } from 'i18next'
import Cookies from 'js-cookie'
import moment from 'moment'
import 'moment/locale/es'

import {
  durationString,
  offsetString,
  timeOfDayString,
} from 'src/service-design/core/domain/dates'
import { flags } from 'src/service-design/shared/flags'

import enUS from './en'
import enAU from './en-AU'

const defaultLanguage = flags.defaultLanguage ? 'en' : 'en-AU'
const selectedLanguage = () => Cookies.get('lng') || defaultLanguage
const i18nOptions: InitOptions = {
  resources: {
    en: enUS,
    'en-AU': enAU,
  },
  interpolation: {
    escapeValue: false,
    format: (value: any, format: string) => {
      switch (format) {
        case 'durationString':
          return durationString(value)
        case 'offsetString':
          return offsetString(value)
        case 'timeOfDayString':
          return timeOfDayString(value)
        default:
          throw Error('Invalid format')
      }
    },
  },
  keySeparator: '||',
  nsSeparator: '::',
  lng: selectedLanguage(),
  fallbackLng: 'en',
  defaultNS: 'common',
  ns: ['common'],
  compatibilityJSON: 'v3',
}

if (process.env.NODE_ENV === 'development') {
  i18nOptions.saveMissing = true
  i18nOptions.missingKeyHandler = (
    lng: readonly string[],
    ns: string,
    key: string,
    fallbackValue: any,
  ) => {
    console.warn(
      `Language ${lng} is missing translation for ${ns}::"${key}", falling back to "${fallbackValue}"`,
    )
  }
  i18nOptions.missingInterpolationHandler = (text: string, value: string) => {
    console.warn(`${value} is missing interpolation for translation ${text}`)
  }
}

i18n.init(i18nOptions)
moment.locale(selectedLanguage())
export { i18n, i18nOptions, selectedLanguage }
export default i18n
